import $ from "jquery";
import { logFactory, vendors, MediaQueryHelper } from "GlobalSite";

const compName = "comp_aem_hp-carousel";
const Log = logFactory(compName);
const { log } = Log;

const {
  splide: { Splide, fr },
} = vendors;

log("--->");

const breakPointValueM = MediaQueryHelper?.getBreakPointValue?.("m") ?? 1024;

$(function domReady() {
  new Splide("#hpCarousel", {
    type: "loop",
    perPage: 1,
    i18n: fr,
    mediaQuery: "min",
    breakpoints: {
      [breakPointValueM]: {
        autoWidth: true,
        gap: 52,
      },
    },
  }).mount();
});
